import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { Permission, Page } from "@/types";

type PermissionState = {
  permissionPage: Page<Permission>;
};

const permission: Module<PermissionState, unknown> = {
  namespaced: true,
  state: () => ({
    permissionPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getPermission: (state) => (permissionId: string) =>
      state.permissionPage.docs.find((p) => p._id === permissionId),
    permissionPage: (state) => state.permissionPage,
  },
  mutations: {
    ADD_PERMISSION: (state, _permission) => {
      let idx = -1;
      state.permissionPage.docs.map((pg, i) => {
        if (pg._id === _permission._id) idx = i;
      });
      if (idx === -1) state.permissionPage.docs.push(_permission);
      else Vue.set(state.permissionPage.docs, idx, _permission);
    },
    SET_PERMISSION_PAGE: (state, list) => {
      state.permissionPage = list;
    },
    REMOVE_PERMISSION(state, permission) {
      let idx = -1;
      state.permissionPage.docs.map((pg, i) => {
        if (pg._id === permission._id) idx = i;
      });
      if (idx > -1) state.permissionPage.docs.splice(idx, 1);
    },
  },
  actions: {
    fetchPermission(context, params = "") {
      api
        .get(`/v1/permission${params}`)
        .then((response) => {
          context.commit("ADD_PERMISSION", response.data.permission);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchPermissionList(context, params = "") {
      return await api
        .get(`/v1/permission${params}`)
        .then((response) => {
          context.commit("SET_PERMISSION_PAGE", response.data.permissionPage);
          return response.data.permissionPage;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createPermission(context, payload) {
      return await api
        .post(`/v1/permission`, payload)
        .then((response) => {
          context.commit("ADD_PERMISSION", response.data.permission);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Permission created",
            },
            { root: true }
          );
          return response.data.permission;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updatePermission(
      context,
      data: { id: string; permission: Permission }
    ) {
      return await api
        .put(`/v1/permission/${data.id}`, data.permission)
        .then((response) => {
          context.commit("ADD_PERMISSION", response.data.permission);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Permission updated",
            },
            { root: true }
          );
          return response.data.permission;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    deletePermission(context, id) {
      api
        .delete(`/v1/permission${id}`)
        .then((response) => {
          context.commit("REMOVE_PERMISSION", response.data.permission);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Permission deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default permission;
