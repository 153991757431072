















































































import { Employee } from "@/types";
import Vue, { PropType } from "vue";

import rosterStoreModule from "@/store/modules/rosterEntry";
import { createNamespacedHelpers } from "vuex";
import { readableDate } from "@/util/filters";

const { mapActions: rosterActions } = createNamespacedHelpers("ROSTER_");

export default Vue.extend({
  name: "RosterForm",
  props: {
    roster: {
      type: Object as PropType<{
        entryId: string;
        date: Date;
        employee: Employee;
        slot: { startTime: string; endTime: string };
      }>,
      required: true,
    },
  },
  data() {
    return {
      valid: false,
      type: "",
      repeats: "",
      startTime: "",
      endTime: "",
      startTimeRules: [(v: string) => !!v || "Start Time is required"],
      endTimeRules: [(v: string) => !!v || "End Time is required"],
      typeRules: [(v: string) => !!v || "Type is required"],
      repeatsRules: [(v: string) => !!v || "Repeat field is required"],
    };
  },
  watch: {
    roster() {
      this.resetForm();
    },
  },
  mounted() {
    this.resetForm();
  },
  methods: {
    ...rosterActions(["bulkUpdateRosterEntry", "deleteRosterEntry"]),
    readableDate,
    validateForm() {
      const valid = (
        this.$refs.rosterForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;
      this.bulkUpdateRosterEntry({
        rosterEntries: [
          {
            rosterEntryId: this.roster.entryId,
            slot: {
              startTime: this.startTime,
              endTime: this.endTime,
            },
          },
        ],
      }).then((r) => {
        if (r) this.$emit("data-saved", true);
      });
    },
    resetForm() {
      this.startTime = this.roster.slot.startTime;
      this.endTime = this.roster.slot.endTime;
      this.repeats = "";
      this.type = "";
      (
        this.$refs.rosterForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();
    },
    cancel() {
      this.$emit("cancel", true);
    },
    deleteSlot() {
      // delete logic
      this.deleteRosterEntry(this.roster.entryId).then((deleted) => {
        if (deleted) this.$emit("data-saved", true);
      });
    },
    addDate() {
      this.$emit("add-date");
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("ROSTER_")) {
      this.$store.registerModule("ROSTER_", rosterStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("ROSTER_");
  },
});
