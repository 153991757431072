








































import Vue from "vue";

import EmployeeList from "@/components/employee/EmployeeList.vue";
import Leaves from "@/components/employee/Leaves.vue";
import Roster from "@/components/employee/Roster.vue";
import PermissionGroups from "@/components/employee/PermissionGroups.vue";
import PermissionList from "@/components/employee/PermissionList.vue";
import ActionButtons from "@/components/ActionButtons.vue";
import { PermissionGroup } from "@/types";
import JobTitleList from "@/components/employee/JobTitleList.vue";

export default Vue.extend<any, any, any, any>({
  name: "Employees",
  components: {
    EmployeeList,
    Leaves,
    Roster,
    PermissionGroups,
    PermissionList,
    ActionButtons,
    JobTitleList,
  },
  data: () => ({
    tab: 0,
    viewPermissions: false,
    permissionGroup: undefined as undefined | PermissionGroup,
  }),
  methods: {
    showPermissions(group: PermissionGroup) {
      this.permissionGroup = group;
      this.viewPermissions = true;
    },
  },
});
