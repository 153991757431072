









































































import Vue, { PropType } from "vue";
import { createNamespacedHelpers } from "vuex";

import { EmployeePermission, PermissionGroup } from "@/types";

import employeePermissionStoreModule from "@/store/modules/employeePermission";

const { mapActions: permissionActions, mapGetters: permissionGetters } =
  createNamespacedHelpers("PERMISSIONS");

export default Vue.extend<any, any, any, any>({
  name: "EmployeePermissionList",
  props: {
    permissionGroup: {
      type: Object as PropType<PermissionGroup>,
      required: true,
    },
    selectedGroupId: {
      type: String,
      required: true,
      default: "",
    },
  },
  data: () => ({
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Email", value: "email" },
      { text: "Role", value: "role" },
      { text: "Action", value: "action" },
    ],
    permission: undefined as undefined | EmployeePermission,
    showDeleteDialog: false,
    options: {} as { page: number },
  }),
  watch: {
    selectedGroupId() {
      if (this.selectedGroupId === this.permissionGroup._id) {
        this.fetchPermissions();
      }
    },
    options: {
      handler() {
        this.fetchPermissions();
      },
      deep: true,
    },
  },
  created() {
    this.fetchPermissions();
  },
  computed: {
    ...permissionGetters(["employeePermissionPage"]),
  },
  methods: {
    ...permissionActions([
      "fetchEmployeePermissionList",
      "deleteEmployeePermission",
    ]),
    fetchPermissions() {
      this.fetchEmployeePermissionList(
        `?permissionGroupId=${this.permissionGroup._id}&page=${
          this.options.page || 1
        }`
      ).then((page) => {
        if (page) this.$emit("total-permissions", page.total);
      });
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("PERMISSIONS")) {
      this.$store.registerModule("PERMISSIONS", employeePermissionStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("PERMISSIONS");
  },
});
