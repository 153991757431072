var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"permission"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.acl,"hide-default-footer":true},scopedSlots:_vm._u([{key:"header.basic",fn:function(ref){
var header = ref.header;
return [_c('v-btn',{attrs:{"text":"","disabled":_vm.role.toLowerCase() === 'owner'},on:{"click":_vm.toggleBasic}},[_vm._v(_vm._s(header.text))])]}},{key:"header.medium",fn:function(ref){
var header = ref.header;
return [_c('v-btn',{attrs:{"text":"","disabled":_vm.role.toLowerCase() === 'owner'},on:{"click":_vm.toggleMedium}},[_vm._v(_vm._s(header.text))])]}},{key:"header.low",fn:function(ref){
var header = ref.header;
return [_c('v-btn',{attrs:{"text":"","disabled":_vm.role.toLowerCase() === 'owner'},on:{"click":_vm.toggleLow}},[_vm._v(_vm._s(header.text))])]}},{key:"header.owner",fn:function(ref){
var header = ref.header;
return [_c('v-btn',{attrs:{"text":"","disabled":_vm.role.toLowerCase() === 'owner'},on:{"click":_vm.toggleOwner}},[_vm._v(_vm._s(header.text))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(_vm._s(item.name))])]}},{key:"item.basic",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-checkbox',{attrs:{"disabled":_vm.role.toLowerCase() === 'owner'},on:{"change":function($event){return _vm.updatePermission(index, 'basic')}},model:{value:(item.basic),callback:function ($$v) {_vm.$set(item, "basic", $$v)},expression:"item.basic"}})]}},{key:"item.medium",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-checkbox',{attrs:{"disabled":_vm.role.toLowerCase() === 'owner'},on:{"change":function($event){return _vm.updatePermission(index, 'medium')}},model:{value:(item.medium),callback:function ($$v) {_vm.$set(item, "medium", $$v)},expression:"item.medium"}})]}},{key:"item.low",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-checkbox',{attrs:{"disabled":_vm.role.toLowerCase() === 'owner'},on:{"change":function($event){return _vm.updatePermission(index, 'low')}},model:{value:(item.low),callback:function ($$v) {_vm.$set(item, "low", $$v)},expression:"item.low"}})]}},{key:"item.owner",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-checkbox',{attrs:{"disabled":_vm.role.toLowerCase() === 'owner'},on:{"change":function($event){return _vm.updatePermission(index, 'owner')}},model:{value:(item.owner),callback:function ($$v) {_vm.$set(item, "owner", $$v)},expression:"item.owner"}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }