



































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import { PropType } from "vue/types/options";
import moment from "moment-timezone";
const tz = localStorage.getItem("tz") ?? "Africa/Nairobi";

import { Business, Employee, Page, Role, RosterEntry } from "@/types";
import rosterEntryStoreModule from "@/store/modules/rosterEntry";
import employeeStoreModule from "@/store/modules/employee";

const { mapActions: rosterEntryActions, mapGetters: rosterEntryGetters } =
  createNamespacedHelpers("EDIT_BULK_ROSTER_FORM");

const { mapActions: employeeActions, mapGetters: employeeGetters } =
  createNamespacedHelpers("ROSTER_FORM_EMPLOYEES");

export default Vue.extend({
  name: "EditRosterForm",
  data() {
    return {
      valid: false,
      days: [
        { name: "Monday", slot: { startTime: "", endTime: "" }, _id: "" },
        { name: "Tuesday", slot: { startTime: "", endTime: "" }, _id: "" },
        { name: "Wednesday", slot: { startTime: "", endTime: "" }, _id: "" },
        { name: "Thursday", slot: { startTime: "", endTime: "" }, _id: "" },
        { name: "Friday", slot: { startTime: "", endTime: "" }, _id: "" },
        { name: "Saturday", slot: { startTime: "", endTime: "" }, _id: "" },
        { name: "Sunday", slot: { startTime: "", endTime: "" }, _id: "" },
      ],
      startTimeRules: [(v: string) => !!v || "Start Time is required"],
      endTimeRules: [(v: string) => !!v || "End Time is required"],
      dateRules: [(v: string) => !!v || "Date is required"],
      page: 1,
      newRosterEntryEntreis: [] as {
        date: string;
        startTime: string;
        endTime: string;
        employeeIds: string[];
      }[],
      periods: [
        { label: "Weekly", value: "week" },
        { label: "Monthly", value: "month" },
        { label: "Yearly", value: "year" },
      ],
      period: "week" as "week" | "month" | "year",
      duration: 1,
      durationRules: [
        (v: number) => !!v || "Duration is required",
        (v: number) => v >= 1 || "Minimun Duration is 1",
      ],
      staffRules: [(v: string) => !!v || "Staff is required"],
      startDate: "",
      endDate: "",
      deletions: [] as string[],
    };
  },
  props: {
    employee: {
      type: Object as PropType<Employee>,
      required: true,
    },
  },
  created() {
    this.fetchData();
    // const startOfWeek = moment().startOf("isoWeek").tz(tz).format("YYYY-MM-DD");
    // const rosterEntry2 = [];
    // for (let i = 0; i < 7; i++) {
    //   const d = new Date(startOfWeek);
    //   const date = moment(d.setDate(d.getDate() + i)).tz(tz).format("YYYY-MM-DD");
    //   rosterEntry2.push({ startTime: "", endTime: "", employeeIds: [], date });
    // }
    // this.newRosterEntryEntreis = rosterEntry2;
  },
  watch: {
    page: "fetchData",
    employee: "fetchData",
  },
  computed: {
    ...rosterEntryGetters(["rosterEntryPage"]),
    ...employeeGetters(["employeePage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...rosterEntryActions([
      "fetchRosterEntryList",
      "bulkUpdateRosterEntry",
      "bulkCreateRosterEntry",
      "bulkDeleteRosterEntry",
    ]),
    ...employeeActions(["fetchEmployeeList"]),
    validateForm() {
      const valid = (
        this.$refs.rosterEntryForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;

      const rosterEntries: {
        employeeIds: [string];
        slot: {
          startTime: string;
          endTime: string;
        };
        day: string;
        startDate: string;
        endDate: string;
      }[] = this.days
        .filter((item) => !item._id)
        .map((day) => ({
          employeeIds: [this.employee._id],
          slot: day.slot,
          day: day.name,
          startDate: this.startDate,
          endDate: this.endDate,
        }));

      let p1;

      if (rosterEntries.length) {
        p1 = this.bulkCreateRosterEntry({ rosterEntries });
      }

      const update = this.days
        .filter((item) => item._id)
        .map((day) => ({
          rosterEntryId: day._id,
          slot: {
            startTime: day.slot.startTime,
            endTime: day.slot.endTime,
          },
          startDate: this.startDate,
          endDate: this.endDate,
        }));

      let p2;

      if (update.length) {
        p2 = this.bulkUpdateRosterEntry({ rosterEntries: update });
      }

      let p3;

      if (this.deletions.length) {
        p3 = this.bulkDeleteRosterEntry(this.deletions);
      }

      Promise.all([p1, p2, p3]).then((v) => {
        if (v.length) {
          this.$emit("data-saved", true);
        }
      });
    },
    resetForm(page: Page<RosterEntry>) {
      this.startDate = page.docs.length
        ? moment(page.docs[0].startDate).tz(tz).format("YYYY-MM-DD")
        : moment().tz(tz).format("YYYY-MM-DD");
      if (page.docs.length)
        this.endDate = moment(page.docs[0].endDate).tz(tz).format("YYYY-MM-DD");
      for (let i = 0; i < page.docs.length; i++) {
        const element = page.docs[i];

        const index = this.days.findIndex((day) => day.name === element.day);

        if (index > -1)
          this.days[index] = {
            ...this.days[index],
            ...{
              _id: element._id as string,
              slot: {
                startTime: element.slot.startTime,
                endTime: element.slot.endTime,
              },
            },
          };
      }
      (
        this.$refs.rosterEntryForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();
    },
    cancel() {
      this.$emit("cancel", true);
    },
    removeRow(index: number) {
      if (this.days[index]._id) this.deletions.push(this.days[index]._id);
      this.days.splice(index, 1);
    },
    fetchData() {
      const bid = (this.role.business as Business)._id;
      // const params = `?businessId=${bid}&page=${this.page}&sort=date`;
      this.fetchRosterEntryList(`?employeeId=${this.employee._id}`).then(
        (page) => {
          if (page) {
            this.resetForm(page);
          }
        }
      );
      this.fetchEmployeeList(`?businessId=${bid}`);
    },
    applyToAll(index: number) {
      for (let i = 0; i < this.days.length; i++) {
        if (i != index) {
          this.days[i].slot.startTime = this.days[index].slot.startTime;
          this.days[i].slot.endTime = this.days[index].slot.endTime;
        }
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("EDIT_BULK_ROSTER_FORM")) {
      this.$store.registerModule(
        "EDIT_BULK_ROSTER_FORM",
        rosterEntryStoreModule
      );
    }
    if (!this.$store.hasModule("ROSTER_FORM_EMPLOYEES")) {
      this.$store.registerModule("ROSTER_FORM_EMPLOYEES", employeeStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("EDIT_BULK_ROSTER_FORM");
    this.$store.unregisterModule("ROSTER_FORM_EMPLOYEES");
  },
});
