














































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import jobTitleStoreModule from "@/store/modules/jobTitle";
import { Business, JobTitle, Role } from "@/types";

const { mapActions: jobTitleActions, mapGetters: jobTitleGetters } =
  createNamespacedHelpers("JOB_TITLES");

export default Vue.extend<any, any, any, any>({
  name: "JobTitleList",
  data: () => ({
    jobTitle: undefined as undefined | JobTitle,
    showJobTitleDialog: false,
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Action", value: "action" },
    ],
    options: {} as { page: number },
    rosterDialog: false,
    nameRules: [(v: string) => !!v || "Job title is required"],
    name: "",
    showJobTitleDeleteDialog: false,
  }),
  watch: {
    role() {
      if (this.role) {
        this.fetchJobTitles();
      }
    },
    options: {
      handler() {
        this.fetchJobTitles();
      },
      deep: true,
    },
    jobTitle() {
      if (this.jobTitle) {
        this.name = this.jobTitle.name;
      }
    },
  },
  computed: {
    ...jobTitleGetters(["jobTitlePage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...jobTitleActions(["fetchJobTitleList"]),
    ...jobTitleActions(["createJobTitle", "updateJobTitle", "deleteJobTitle"]),
    fetchJobTitles() {
      if (this.role) {
        this.fetchJobTitleList(
          `?businessId=${(this.role.business as Business)._id}&page=${
            this.options.page || 1
          }`
        );
      }
    },
    saveJobTitle() {
      const valid = (
        this.$refs.titleForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;

      if (this.jobTitle && this.jobTitle._id) {
        this.updateJobTitle({
          id: this.jobTitle._id,
          jobTitle: { name: this.name },
        }).then((jobTitle) => {
          if (jobTitle) {
            this.showJobTitleDialog = false;
            this.fetchJobTitleList(
              `?businessId=${(this.role.business as Business)._id}&page=${
                this.options.page || 1
              }`
            );
          }
        });
        return;
      }

      this.createJobTitle({
        name: this.name,
        businessId: (this.role.business as Business)._id,
      }).then((title) => {
        if (title) {
          this.showJobTitleDialog = false;
          this.fetchJobTitleList(
            `?businessId=${(this.role.business as Business)._id}&page=${
              this.options.page || 1
            }`
          );
        }
      });
    },
    removeJobTitle(jobTitle: JobTitle) {
      this.deleteJobTitle(jobTitle._id).then((title) => {
        if (title) {
          this.showJobTitleDeleteDialog = false;
        }
      });
    },
    resetForm() {
      (
        this.$refs.titleForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();

      this.name = "";
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("JOB_TITLES")) {
      this.$store.registerModule("JOB_TITLES", jobTitleStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("EMPLOYEE_LIST");
    this.$store.unregisterModule("JOB_TITLES");
  },
});
