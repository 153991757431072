
































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import leaveStoreModule from "@/store/modules/leave";
import locationStoreModule from "@/store/modules/location";
import employeeStoreModule from "@/store/modules/employee";

import { Business, Role, _Location } from "@/types";

const { mapActions: leaveActions } = createNamespacedHelpers("LEAVE_FORM_");

const { mapActions: locationActions } =
  createNamespacedHelpers("LEAVE_LOCATIONS");

const { mapActions: employeeActions, mapGetters: employeeGetters } =
  createNamespacedHelpers("EMPLOYEES_");

export default Vue.extend<any, any, any, any>({
  name: "LeaveForm",
  data() {
    return {
      valid: false,
      description: "",
      startDate: "",
      endDate: "",
      location: "",
      employeeId: "",
      startDateRules: [(v: string) => !!v || "Start Date is required"],
      endDateRules: [(v: string) => !!v || "End Date is required"],
      locationRules: [(v: string) => !!v || "Location is required"],
      descriptionRules: [(v: string) => !!v || "Description field is required"],
      staffRules: [(v: string) => !!v || "Staff is required"],
      locations: [] as string[],
      latlng: "",
    };
  },
  mounted() {
    this.fetchData();
    this.resetForm();
  },
  computed: {
    ...employeeGetters(["employeePage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...leaveActions(["createLeave", "updateLeave"]),
    ...locationActions(["fetchLocationList"]),
    ...employeeActions(["fetchEmployeeList"]),
    validateForm() {
      const valid = (
        this.$refs.leaveForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;

      const payload = {
        startDate: this.startDate,
        endDate: this.endDate,
        // location: this.location,
        description: this.description,
        employeeId: this.employeeId,
      };
      this.createLeave(payload).then((r) => {
        if (r) this.$emit("data-saved", true);
      });
    },
    resetForm() {
      this.startDate = "";
      this.endDate = "";
      this.location = "";
      this.description = "";
      (
        this.$refs.leaveForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();
    },
    searchLocation(q: string) {
      if (q) {
        const bid = (this.role.business as Business)._id;
        let params = `?businessId=${bid}&q=${q}`;
        this.fetchLocationList(params).then((locationPage) => {
          if (locationPage) {
            this.locations = locationPage.docs.map((l: _Location) => l.name);
          }
        });
      }
    },
    fetchData() {
      if (this.role) {
        this.fetchEmployeeList(
          `?businessId=${(this.role.business as Business)._id}`
        );
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("LEAVE_FORM_")) {
      this.$store.registerModule("LEAVE_FORM_", leaveStoreModule);
    }
    if (!this.$store.hasModule("LEAVE_LOCATIONS")) {
      this.$store.registerModule("LEAVE_LOCATIONS", locationStoreModule);
    }
    if (!this.$store.hasModule("EMPLOYEES_")) {
      this.$store.registerModule("EMPLOYEES_", employeeStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("LEAVE_FORM_");
    this.$store.unregisterModule("LEAVE_LOCATIONS");
    this.$store.unregisterModule("EMPLOYEES_");
  },
});
