















































































































































































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

// import EmployeeWorkingHoursForm from "./EmployeeWorkingHoursForm.vue";
import EmployeeForm from "../EmployeeForm.vue";
import employeeStoreModule from "@/store/modules/employee";
import employeePermissionStoreModule from "@/store/modules/employeePermission";
import jobTitleStoreModule from "@/store/modules/jobTitle";
import { Employee, EmployeePermission } from "@/types";
import PermissionGroupForm from "./PermissionGroupForm.vue";
import EditBulkRosterForm from "./EditBulkRosterForm.vue";
import EditRosterForm from "./EditRosterForm.vue";

const { mapActions: employeeActions, mapGetters: employeeGetters } =
  createNamespacedHelpers("EMPLOYEE_LIST");

const { mapActions: jobTitleActions } =
  createNamespacedHelpers("JOB_TITLE_FORM");

const VUE_APP_API_URL = process.env.VUE_APP_API_URL;

export default Vue.extend<any, any, any, any>({
  components: {
    // EmployeeWorkingHoursForm,
    EmployeeForm,
    PermissionGroupForm,
    EditBulkRosterForm,
    EditRosterForm,
  },
  name: "EmployeeList",
  data: () => ({
    employee: undefined as undefined | Employee,
    showEmployeeDialog: false,
    showWorkingHoursDialog: false,
    showJobTitleDialog: false,
    showAddGroupDialog: false,
    selectedStaff: "All",
    selectedLocation: "All",
    jobTitle: "",
    headers: [
      {
        text: "Name",
        // align: "start",
        sortable: true,
        value: "fullName",
      },
      { text: "Email", value: "email" },
      { text: "Phone", value: "phone" },
      { text: "Action", value: "action" },
    ],
    apiUrl: VUE_APP_API_URL,
    options: {} as { page: number },
    rosterDialog: false,
    jobTitleRules: [(v: string) => !!v || "Job title is required"],
    exporting: false,
    deleteDialog: false,
  }),
  watch: {
    permission() {
      if (this.permission) {
        this.fetchEmployees();
      }
    },
    options: {
      handler() {
        this.fetchEmployees();
      },
      deep: true,
    },
  },
  computed: {
    ...employeeGetters(["employeePage"]),
    permission(): EmployeePermission {
      return this.$store.getters.permission;
    },
  },
  filters: {
    maskPhone(phone: string) {
      if (!phone) return "";
      let masked = "";
      for (let i = 0; i < phone.length; i++) {
        if (i <= 3 || i >= 9) {
          masked += phone[i];
        } else {
          masked += "*";
        }
      }

      return masked;
    },
  },
  methods: {
    ...employeeActions([
      "fetchEmployeeList",
      "exportEmployees",
      "fetchEmployee",
      "deleteEmployee",
    ]),
    ...jobTitleActions(["createJobTitle"]),
    viewEmployee(employeeId: string) {
      this.$router.push(`/staff/${employeeId}`);
    },
    closeModal(employee: Employee) {
      this.showEmployeeDialog = false;
      this.rosterDialog = true;
      this.employee = employee;
      this.fetchEmployees();
    },
    fetchEmployees() {
      if (this.permission) {
        const bid = this.permission.employee.business._id;
        const permitted = !!this.permission.permissionGroup.permissions.find(
          (p) => {
            if (p.section == "employees") {
              return !!p.acl.find(
                (acl) => acl.name == "All Employees" && !acl.basic
              );
            }
            return false;
          }
        );

        if (permitted) {
          const page = this.options.page || 1;
          this.fetchEmployeeList(`?businessId=${bid}&page=${page}`);
        } else {
          this.fetchEmployee(`?employeeId=${this.permission.employee._id}`);
        }
      }
    },
    createTitle() {
      const valid = (
        this.$refs.titleForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;
      this.createJobTitle({
        name: this.jobTitle,
        businessId: this.permission.employee.business._id,
      }).then((title) => {
        if (title) {
          this.showJobTitleDialog = false;
          this.showEmployeeDialog = true;
          this.jobTitle = "";
        }
      });
    },
    _export() {
      const params = `?businessId=${this.permission.employee.business?._id}`;
      this.exporting = true;
      this.exportEmployees(params).then((res) => {
        this.exporting = false;
        if (res.data) {
          const fileURL = window.URL.createObjectURL(new Blob([res.data]));
          const fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", "employees.csv");
          document.body.appendChild(fileLink);

          fileLink.click();
        }
      });
    },
    doDelete() {
      this.deleteEmployee(this.employee._id).then((emp) => {
        if (emp) {
          this.deleteDialog = false;
        }
      });
    },
    // searchEmployee(q: string) {
    //   if (q) {
    //     const bid = (this.role.business as Business)?._id;
    //     const params = `?businessId=${bid}&q=${q}`;
    //     this.fetchEmployeeList(params);
    //   }
    // },
  },
  beforeCreate() {
    if (!this.$store.hasModule("EMPLOYEE_LIST")) {
      this.$store.registerModule("EMPLOYEE_LIST", employeeStoreModule);
    }
    if (!this.$store.hasModule("JOB_TITLE_FORM")) {
      this.$store.registerModule("JOB_TITLE_FORM", jobTitleStoreModule);
    }
    if (!this.$store.hasModule("EMPLOYEE_LIST_PERMISSION")) {
      this.$store.registerModule(
        "EMPLOYEE_LIST_PERMISSION",
        employeePermissionStoreModule
      );
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("EMPLOYEE_LIST");
    this.$store.unregisterModule("JOB_TITLE_FORM");
  },
});
