








































































































































import { Employee, Roster } from "@/types";
import Vue, { PropType } from "vue";

import rosterStoreModule from "@/store/modules/roster";
import { createNamespacedHelpers } from "vuex";
import moment from "moment-timezone";
const tz = localStorage.getItem("tz") ?? "Africa/Nairobi";

const { mapActions: rosterActions, mapGetters: rosterGetters } =
  createNamespacedHelpers("EDIT_BULK_ROSTER_FORM");

export default Vue.extend<any, any, any, any>({
  name: "EditBulkRosterForm",
  props: {
    employee: {
      type: Object as PropType<Employee>,
      required: true,
    },
  },
  data() {
    return {
      valid: false,
      roster: [] as {
        rosterId: string;
        date: string;
        startTime: string;
        endTime: string;
      }[],
      startTimeRules: [(v: string) => !!v || "Start Time is required"],
      endTimeRules: [(v: string) => !!v || "End Time is required"],
      dateRules: [(v: string) => !!v || "Date is required"],
      page: 1,
      newRosterEntreis: [] as {
        date: string;
        startTime: string;
        endTime: string;
      }[],
      periods: [
        { label: "Weekly", value: "week" },
        { label: "Monthly", value: "month" },
        { label: "Yearly", value: "year" },
      ],
      period: "week" as "week" | "month" | "year",
      duration: 1,
      durationRules: [
        (v: number) => !!v || "Duration is required",
        (v: number) => v >= 1 || "Minimun Duration is 1",
      ],
    };
  },
  created() {
    this.fetchData();

    // const startOfWeek = moment().startOf("isoWeek").tz(tz).format("YYYY-MM-DD");
    // const roster2 = [];
    // for (let i = 0; i < 7; i++) {
    //   const d = new Date(startOfWeek);

    //   const date = moment(d.setDate(d.getDate() + i)).tz(tz).format("YYYY-MM-DD");

    //   roster2.push({ startTime: "", endTime: "", date });

    //   this.newRosterEntreis = roster2;
    // }
  },
  watch: {
    page: "fetchData",
  },
  computed: {
    ...rosterGetters(["rosterPage"]),
  },
  methods: {
    ...rosterActions([
      "fetchRosterList",
      "bulkUpdateRoster",
      "bulkCreateRoster",
    ]),
    validateForm() {
      const valid = (
        this.$refs.rosterForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;

      const toDelete = this.rosterPage.docs
        .filter(
          (r: Roster) => !this.roster.map((_r) => _r.rosterId).includes(r._id)
        )
        .map((r: Roster) => r._id);

      this.bulkUpdateRoster({
        roster: this.roster.map((r) => ({
          date: moment(r.date).toISOString(),
          employeeId: this.employee._id,
          rosterId: r.rosterId,
          slot: {
            to: r.endTime,
            from: r.startTime,
          },
        })),
        toDelete,
      }).then((r) => {
        if (r) this.$emit("data-saved", true);
      });

      const dates: { date: string; startTime: string; endTime: string }[] = [];

      for (let i = 0; i < this.newRosterEntreis.length; i++) {
        const d = this.newRosterEntreis[i];
        const start = moment(d.date).toISOString();
        const end = moment(start).add(this.duration, this.period).toISOString();

        let s = d.date;

        for (
          let k = new Date(start);
          k < new Date(end);
          k.setDate(k.getDate() + 1)
        ) {
          let day;
          if (this.period === "week" && k.getDay() === new Date(s).getDay())
            day = k;
          else if (
            this.period === "month" &&
            k.getDate() === new Date(s).getDate()
          )
            day = k;
          else if (
            this.period === "year" &&
            k.getDate() === new Date(s).getDate() &&
            k.getMonth() === new Date(s).getMonth()
          )
            day = k;

          if (day)
            dates.push({
              date: moment(day).tz(tz).format("YYYY-MM-DD"),
              startTime: d.startTime,
              endTime: d.endTime,
            });

          s = moment(s)
            .add(this.duration, this.period)
            .tz(tz)
            .format("YYYY-MM-DD");
        }
      }

      const roster: {
        date: string;
        employeeId: string;
        slot: {
          to: string;
          from: string;
        };
      }[] = [];

      dates.map((r) =>
        roster.push({
          date: moment(r.date).toISOString(),
          employeeId: this.employee._id,
          slot: {
            to: r.endTime,
            from: r.startTime,
          },
        })
      );
      this.bulkCreateRoster({ roster }).then((r) => {
        if (r) this.$emit("data-saved", true);
      });
    },
    resetForm() {
      const roster: any[] = [];
      for (let i = 0; i < this.rosterPage.docs.length; i++) {
        const r = this.rosterPage.docs[i];
        const d = new Date(r.date);

        const date = moment(d).tz(tz).format("YYYY-MM-DD");

        roster.push({
          rosterId: r._id,
          startTime: r.slot.from,
          endTime: r.slot.to,
          date,
        });
      }

      this.roster = roster;

      this.page = this.rosterPage.page;
      (
        this.$refs.rosterForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();
    },
    cancel() {
      this.$emit("cancel", true);
    },
    removeRow(index: number) {
      this.roster.splice(index, 1);
    },
    removeNewRosterEntry(index: number) {
      this.newRosterEntreis.splice(index, 1);
    },
    fetchData() {
      this.fetchRosterList(
        `?employeeId=${this.employee._id}&page=${this.page}&limit=5`
      ).then((r) => {
        if (r) {
          this.resetForm();
        }
      });
    },
    addRow() {
      this.newRosterEntreis.push({
        startTime: "",
        endTime: "",
        date: moment().tz(tz).format("YYYY-MM-DD"),
      });
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("EDIT_BULK_ROSTER_FORM")) {
      this.$store.registerModule("EDIT_BULK_ROSTER_FORM", rosterStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("EDIT_BULK_ROSTER_FORM");
  },
});
