
































































import Vue, { PropType } from "vue";
import { createNamespacedHelpers } from "vuex";

import permissionGroupStoreModule from "@/store/modules/permissionGroup";
import { Permission, PermissionGroup } from "@/types";

const { mapActions: permissionGroupActions } = createNamespacedHelpers(
  "EDIT_PERMISSION_GROUP"
);

import EditPermissionsForm from "./EditPermissionsForm.vue";

export default Vue.extend<any, any, any, any>({
  name: "PermissionList",
  components: {
    EditPermissionsForm,
  },
  props: {
    permissionGroup: {
      type: Object as PropType<PermissionGroup>,
      required: true,
    },
  },
  data: () => ({
    basic: false as boolean | "indeterminate",
    low: false as boolean | "indeterminate",
    medium: false as boolean | "indeterminate",
    owner: false as boolean | "indeterminate",
  }),
  created() {
    if (this.permissionGroup) {
      const { basic, low, medium, owner } = this.checkAcls(
        this.permissionGroup.permissions
      );
      this.basic = basic;
      this.low = low;
      this.medium = medium;
      this.owner = owner;
    }
  },
  methods: {
    ...permissionGroupActions(["updatePermissionGroup"]),
    checkAcls(permissions: Permission[]) {
      let basic: boolean | "indeterminate" = true;
      basic = permissions
        .map((p) => p.acl)
        .every((acls) => acls.every((acl) => acl.basic === true));
      if (!basic) {
        basic = permissions
          .map((p) => p.acl)
          .some((acls) => acls.some((acl) => acl.basic === true))
          ? "indeterminate"
          : false;
      }

      let low: boolean | "indeterminate" = true;
      low = permissions
        .map((p) => p.acl)
        .every((acls) => acls.every((acl) => acl.low === true));
      if (!low) {
        low = permissions
          .map((p) => p.acl)
          .some((acls) => acls.some((acl) => acl.low === true))
          ? "indeterminate"
          : false;
      }

      let medium: boolean | "indeterminate" = true;
      medium = permissions
        .map((p) => p.acl)
        .every((acls) => acls.every((acl) => acl.medium === true));
      if (!medium) {
        medium = permissions
          .map((p) => p.acl)
          .some((acls) => acls.some((acl) => acl.medium === true))
          ? "indeterminate"
          : false;
      }

      let owner: boolean | "indeterminate" = true;
      owner = permissions
        .map((p) => p.acl)
        .every((acls) => acls.every((acl) => acl.owner === true));
      if (!owner) {
        owner = permissions
          .map((p) => p.acl)
          .some((acls) => acls.some((acl) => acl.owner === true))
          ? "indeterminate"
          : false;
      }

      return { basic, low, medium, owner };
    },
    update(args: { permission: Permission; section: string }) {
      const permissions = this.permissionGroup.permissions.map((p) => {
        if (p.section === args.section) return args.permission;
        return p;
      });
      this.updatePermissionGroup({
        id: this.permissionGroup._id,
        permissionGroup: {
          permissions: permissions.map((item) => ({
            section: item.section,
            acl: item.acl,
          })),
        },
      }).then((pg) => {
        if (pg) {
          const { basic, low, medium, owner } = this.checkAcls(permissions);
          this.basic = basic;
          this.low = low;
          this.medium = medium;
          this.owner = owner;
        }
      });
    },
    toggleBasic(basic: boolean) {
      if (this.permissionGroup.role.toLowerCase() === "owner") {
        this.$store.dispatch("setToast", {
          title: "Request failed!",
          type: "error",
          text: "Owmer Permission cannot be editted",
        });
        return;
      }
      this.basic = basic;
      this.permissionGroup.permissions.map((p) => {
        p.acl.map((a) => {
          a.basic = basic;
          a.low = false;
          a.medium = false;
          a.owner = false;
        });
      });

      this.updatePermissionGroup({
        id: this.permissionGroup._id,
        permissionGroup: {
          permissions: this.permissionGroup.permissions.map((item) => ({
            section: item.section,
            acl: item.acl,
          })),
        },
      }).then((pg) => {
        if (pg) {
          this.basic = basic;
          this.low = false;
          this.medium = false;
          this.owner = false;
        }
      });
    },
    toggleLow(low: boolean) {
      if (this.permissionGroup.role.toLowerCase() === "owner") {
        this.$store.dispatch("setToast", {
          title: "Request failed!",
          type: "error",
          text: "Owmer Permission cannot be editted",
        });
        return;
      }
      this.low = low;
      this.permissionGroup.permissions.map((p) => {
        p.acl.map((a) => {
          a.basic = false;
          a.low = low;
          a.medium = false;
          a.owner = false;
        });
      });

      this.updatePermissionGroup({
        id: this.permissionGroup._id,
        permissionGroup: {
          permissions: this.permissionGroup.permissions.map((item) => ({
            section: item.section,
            acl: item.acl,
          })),
        },
      }).then((pg) => {
        if (pg) {
          this.basic = false;
          this.low = low;
          this.medium = false;
          this.owner = false;
        }
      });
    },
    toggleMedium(medium: boolean) {
      if (this.permissionGroup.role.toLowerCase() === "owner") {
        this.$store.dispatch("setToast", {
          title: "Request failed!",
          type: "error",
          text: "Owmer Permission cannot be editted",
        });
        return;
      }
      this.medium = medium;
      this.permissionGroup.permissions.map((p) => {
        p.acl.map((a) => {
          a.basic = false;
          a.low = false;
          a.medium = medium;
          a.owner = false;
        });
      });

      this.updatePermissionGroup({
        id: this.permissionGroup._id,
        permissionGroup: {
          permissions: this.permissionGroup.permissions.map((item) => ({
            section: item.section,
            acl: item.acl,
          })),
        },
      }).then((pg) => {
        if (pg) {
          this.basic = false;
          this.low = false;
          this.medium = true;
          this.owner = false;
        }
      });
    },
    toggleOwner(owner: boolean) {
      if (this.permissionGroup.role.toLowerCase() === "owner") {
        this.$store.dispatch("setToast", {
          title: "Request failed!",
          type: "error",
          text: "Owmer Permission cannot be editted",
        });
        return;
      }
      this.owner = owner;
      this.permissionGroup.permissions.map((p) => {
        p.acl.map((a) => {
          a.basic = false;
          a.low = false;
          a.medium = false;
          a.owner = owner;
        });
      });

      this.updatePermissionGroup({
        id: this.permissionGroup._id,
        permissionGroup: {
          permissions: this.permissionGroup.permissions.map((item) => ({
            section: item.section,
            acl: item.acl,
          })),
        },
      }).then((pg) => {
        if (pg) {
          this.basic = false;
          this.low = false;
          this.medium = false;
          this.owner = owner;
        }
      });
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("EDIT_PERMISSION_GROUP")) {
      this.$store.registerModule(
        "EDIT_PERMISSION_GROUP",
        permissionGroupStoreModule
      );
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("EDIT_PERMISSION_GROUP");
  },
});
