import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { Leave, Page } from "@/types";

type LeaveState = {
  leavePage: Page<Leave>;
};

const leave: Module<LeaveState, unknown> = {
  namespaced: true,
  state: () => ({
    leavePage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getLeave: (state) => (leaveId: string) =>
      state.leavePage.docs.find((c) => c._id === leaveId),
    leavePage: (state) => state.leavePage,
  },
  mutations: {
    ADD_LEAVE: (state, _leave) => {
      let idx = -1;
      state.leavePage.docs.map((a, i) => {
        if (a._id === _leave._id) idx = i;
      });
      if (idx === -1) state.leavePage.docs.push(_leave);
      else Vue.set(state.leavePage.docs, idx, _leave);
    },
    SET_LEAVE_PAGE: (state, list) => {
      state.leavePage = list;
    },
    REMOVE_LEAVE(state, leave) {
      let idx = -1;
      state.leavePage.docs.map((a, i) => {
        if (a._id === leave._id) idx = i;
      });
      if (idx > -1) state.leavePage.docs.splice(idx, 1);
    },
  },
  actions: {
    fetchLeave(context, params = "") {
      api
        .get(`/v1/leave${params}`)
        .then((response) => {
          context.commit("ADD_LEAVE", response.data.leave);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchLeaveList(context, params = "") {
      api
        .get(`/v1/leave${params}`)
        .then((response) => {
          context.commit("SET_LEAVE_PAGE", response.data.leavePage);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createLeave(context, payload) {
      return await api
        .post(`/v1/leave`, payload, {
          headers: { "vendor-module": "employees.Leave" },
        })
        .then((response) => {
          context.commit("ADD_LEAVE", response.data.leave);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Leave created",
            },
            { root: true }
          );
          return response.data.leave;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    updateLeave(context, data: { id: string; leave: Leave }) {
      api
        .put(`/v1/leave/${data.id}`, data.leave)
        .then((response) => {
          context.commit("ADD_LEAVE", response.data.leave);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Leave updated",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    deleteLeave(context, id) {
      api
        .delete(`/v1/leave${id}`)
        .then((response) => {
          context.commit("REMOVE_LEAVE", response.data.leave);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Leave deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default leave;
