















































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import permissionGroupStoreModule from "@/store/modules/permissionGroup";
import employeeStoreModule from "@/store/modules/employee";
import employeePermissionStoreModule from "@/store/modules/employeePermission";
import permissionStoreModule from "@/store/modules/permission";
import { Business, Permission, Role } from "@/types";

const { mapActions: employeePermissionActions } = createNamespacedHelpers(
  "PERMISSION_GROUP_CREATE"
);

const { mapActions: employeeActions, mapGetters: employeeGetters } =
  createNamespacedHelpers("EMPLOYEE_LIST_");

const {
  mapActions: permissionGroupActions,
  // mapGetters: permissionGroupGetters,
} = createNamespacedHelpers("PERMISSION_GROUP");

const { mapActions: permissionActions, mapGetters: permissionGetters } =
  createNamespacedHelpers("PERMISSION");

export default Vue.extend<any, any, any, any>({
  name: "PermissionGroupForm",
  data: () => ({
    valid: false,
    name: "",
    selectedEmployees: [],
    nameRules: [(v: string) => !!v || "Name is required"],
    employeeRules: [(v: string) => !!v || "Staff is required"],
  }),
  watch: {
    group: "resetForm",
    role: "fetchData",
  },
  mounted() {
    this.resetForm();
  },
  created() {
    this.fetchData();
    this.fetchPermissionList();
  },
  computed: {
    ...employeeGetters(["employeePage"]),
    ...permissionGetters(["permissionPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...employeeActions(["fetchEmployeeList"]),
    ...permissionGroupActions([
      "createPermissionGroup",
      "updatePermissionGroup",
    ]),
    ...employeePermissionActions(["bulkCreateEmployeePermission"]),
    ...permissionActions(["fetchPermissionList"]),
    validateGroupForm() {
      const valid = (
        this.$refs.permissionGroupForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;
      const businessId = (this.role?.business as Business)?._id;
      const permissions = this.permissionPage.docs.map((p: Permission) => ({
        section: p.section,
        acl: p.acl,
      }));
      this.createPermissionGroup({
        name: this.name,
        businessId,
        role: this.name.toLowerCase(),
        permissions,
      }).then((group) => {
        if (group) {
          if (this.selectedEmployees.length) {
            const payload = {
              employeePermissions: this.selectedEmployees.map((e) => ({
                employeeId: e,
                permissionGroupId: group._id,
                accessType: "write",
              })),
            };
            this.bulkCreateEmployeePermission(payload).then((permissions) => {
              if (permissions) this.$emit("data-saved", group);
            });
          } else this.$emit("data-saved", group);
        }
      });
    },
    resetForm() {
      this.name = "";
      this.selectedEmployees = [];
      (
        this.$refs.permissionGroupForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();
    },
    fetchData() {
      if (this.role) {
        const params = `?businessId=${(this.role.business as Business)._id}`;
        this.fetchEmployeeList(params);
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("PERMISSION_GROUP")) {
      this.$store.registerModule(
        "PERMISSION_GROUP",
        permissionGroupStoreModule
      );
    }
    if (!this.$store.hasModule("EMPLOYEE_LIST_")) {
      this.$store.registerModule("EMPLOYEE_LIST_", employeeStoreModule);
    }
    if (!this.$store.hasModule("PERMISSION_GROUP_CREATE")) {
      this.$store.registerModule(
        "PERMISSION_GROUP_CREATE",
        employeePermissionStoreModule
      );
    }
    if (!this.$store.hasModule("PERMISSION")) {
      this.$store.registerModule("PERMISSION", permissionStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("PERMISSION_GROUP");
    this.$store.unregisterModule("EMPLOYEE_LIST_");
    this.$store.unregisterModule("PERMISSION_GROUP_CREATE");
    this.$store.unregisterModule("PERMISSION");
  },
});
