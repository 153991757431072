var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"permission-form"},[(_vm.permissionGroup)?_c('h3',[_vm._v(" Edit permissions for "+_vm._s(_vm.permissionGroup.name)+" ")]):_vm._e(),_vm._l((_vm.permissions),function(p,i){return _c('v-data-table',{key:i,attrs:{"headers":p.headers,"items":p.acl,"hide-default-footer":true},scopedSlots:_vm._u([{key:"header.basic",fn:function(ref){
var header = ref.header;
return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.toggleBasic(i)}}},[_vm._v(_vm._s(header.text))])]}},{key:"header.medium",fn:function(ref){
var header = ref.header;
return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.toggleMedium(i)}}},[_vm._v(_vm._s(header.text))])]}},{key:"header.low",fn:function(ref){
var header = ref.header;
return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.toggleLow(i)}}},[_vm._v(_vm._s(header.text))])]}},{key:"header.owner",fn:function(ref){
var header = ref.header;
return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.toggleOwner(i)}}},[_vm._v(_vm._s(header.text))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item.basic",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{on:{"change":function($event){return _vm.updateAcl(item, 'basic')}},model:{value:(item.basic),callback:function ($$v) {_vm.$set(item, "basic", $$v)},expression:"item.basic"}})]}},{key:"item.medium",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{on:{"change":function($event){return _vm.updateAcl(item, 'medium')}},model:{value:(item.medium),callback:function ($$v) {_vm.$set(item, "medium", $$v)},expression:"item.medium"}})]}},{key:"item.low",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{on:{"change":function($event){return _vm.updateAcl(item, 'low')}},model:{value:(item.low),callback:function ($$v) {_vm.$set(item, "low", $$v)},expression:"item.low"}})]}},{key:"item.owner",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{on:{"change":function($event){return _vm.updateAcl(item, 'owner')}},model:{value:(item.owner),callback:function ($$v) {_vm.$set(item, "owner", $$v)},expression:"item.owner"}})]}}],null,true)})}),_c('v-row',[_c('v-col',{staticStyle:{"text-align":"right","margin":"0 10px"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.updatePermission}},[_vm._v("Save")]),_c('v-btn',{on:{"click":_vm.cancel}},[_vm._v("Cancel")])],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }