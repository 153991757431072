import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { RosterEntry, Page } from "@/types";

type RosterEntryState = {
  rosterEntryPage: Page<RosterEntry>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rosterEntryAggregate: any[];
};

const rosterEntry: Module<RosterEntryState, unknown> = {
  namespaced: true,
  state: () => ({
    rosterEntryPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
    rosterEntryAggregate: [],
  }),
  getters: {
    getRosterEntry: (state) => (rosterEntryId: string) =>
      state.rosterEntryPage.docs.find((c) => c._id === rosterEntryId),
    rosterEntryPage: (state) => state.rosterEntryPage,
    rosterEntryAggregate: (state) => state.rosterEntryAggregate,
  },
  mutations: {
    ADD_ROSTER: (state, _rosterEntry) => {
      let idx = -1;
      state.rosterEntryPage.docs.map((r, i) => {
        if (r._id === _rosterEntry._id) idx = i;
      });
      if (idx === -1) state.rosterEntryPage.docs.push(_rosterEntry);
      else Vue.set(state.rosterEntryPage.docs, idx, _rosterEntry);
    },
    SET_ROSTER_PAGE: (state, list) => {
      state.rosterEntryPage = list;
    },
    REMOVE_ROSTER(state, rosterEntry) {
      let idx = -1;
      state.rosterEntryPage.docs.map((r, i) => {
        if (r._id === rosterEntry._id) idx = i;
      });
      if (idx > -1) state.rosterEntryPage.docs.splice(idx, 1);
    },
    SET_ROSTER_AGGREGATE: (state, aggregate) => {
      state.rosterEntryAggregate = aggregate;
    },
  },
  actions: {
    fetchRosterEntry(context, params = "") {
      api
        .get(`/v1/roster-entry${params}`)
        .then((response) => {
          context.commit("ADD_ROSTER", response.data.rosterEntry);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchRosterEntryList(context, params = "") {
      return await api
        .get(`/v1/roster-entry${params}`)
        .then((response) => {
          context.commit("SET_ROSTER_PAGE", response.data.rosterEntryPage);
          return response.data.rosterEntryPage;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createRosterEntry(context, payload) {
      return await api
        .post(`/v1/roster-entry`, payload)
        .then((response) => {
          context.commit("ADD_ROSTER", response.data.rosterEntry);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "RosterEntry created",
            },
            { root: true }
          );
          return response.data.rosterEntry;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async bulkCreateRosterEntry(context, payload) {
      return await api
        .post(`/v1/roster-entry/bulk-create`, payload)
        .then((response) => {
          response.data.rosterEntries.map((r: RosterEntry) =>
            context.commit("ADD_ROSTER", r)
          );
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "RosterEntry saved",
            },
            { root: true }
          );
          return response.data.rosterEntries;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async bulkUpdateRosterEntry(context, payload) {
      return await api
        .put(`/v1/roster-entry/bulk-update`, payload)
        .then((response) => {
          response.data.rosterEntries.map((r: RosterEntry) =>
            context.commit("ADD_ROSTER", r)
          );
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "RosterEntry saved",
            },
            { root: true }
          );
          return response.data.rosterEntries;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updateRosterEntry(
      context,
      data: { id: string; rosterEntry: RosterEntry }
    ) {
      return await api
        .put(`/v1/roster-entry/${data.id}`, data.rosterEntry)
        .then((response) => {
          context.commit("ADD_ROSTER", response.data.rosterEntry);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "RosterEntry updated",
            },
            { root: true }
          );
          return response.data.rosterEntry;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async bulkDeleteRosterEntry(context, ids) {
      return await api
        .post(`/v1/roster-entry/bulk-delete`, { rosterEntryIds: ids })
        .then((response) => {
          // context.commit("REMOVE_ROSTER", response.data.rosterEntry);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Roster Entries entry deleted",
            },
            { root: true }
          );
          return response.data.rosterEntries;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async deleteRosterEntry(context, id) {
      return await api
        .delete(`/v1/roster-entry/${id}`, {
          headers: { "vendor-module": "employees.Roster" },
        })
        .then((response) => {
          context.commit("REMOVE_ROSTER", response.data.rosterEntry);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "RosterEntry entry deleted",
            },
            { root: true }
          );
          return response.data.rosterEntry;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchRosterEntryAggregate(context, params = "") {
      return await api
        .get(`/v1/roster-entry/aggregate${params}`)
        .then((response) => {
          context.commit(
            "SET_ROSTER_AGGREGATE",
            response.data.rosterEntryAggregate
          );
          return response.data.rosterEntryAggregate;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default rosterEntry;
