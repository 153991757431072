
































import Vue, { PropType } from "vue";
import { createNamespacedHelpers } from "vuex";

import { PermissionGroup } from "@/types";
import permissionGroupStoreModule from "@/store/modules/permissionGroup";

const {
  mapActions: permissionGroupActions,
  // mapGetters: permissionGroupGetters,
} = createNamespacedHelpers("PERMISSION_GROUP_");

export default Vue.extend<any, any, any, any>({
  name: "EditPermissionGroupForm",
  props: {
    group: {
      type: Object as PropType<PermissionGroup>,
      required: true,
    },
  },
  data: () => ({
    valid: false,
    name: "",
    nameRules: [(v: string) => !!v || "Name is required"],
  }),
  watch: {
    group: "resetForm",
  },
  mounted() {
    this.resetForm();
  },
  methods: {
    ...permissionGroupActions(["updatePermissionGroup"]),
    validateGroupForm() {
      const valid = (
        this.$refs.permissionGroupForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;
      if (this.group._id) {
        this.updatePermissionGroup({
          id: this.group._id,
          permissionGroup: { name: this.name, role: this.name },
        }).then((group) => {
          if (group) this.$emit("data-saved", group);
        });
        return;
      }
    },
    resetForm() {
      this.name = this.group.name;
      (
        this.$refs.permissionGroupForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("PERMISSION_GROUP_")) {
      this.$store.registerModule(
        "PERMISSION_GROUP_",
        permissionGroupStoreModule
      );
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("PERMISSION_GROUP_");
  },
});
