var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"width":"490"},model:{value:(_vm.showJobTitleDialog),callback:function ($$v) {_vm.showJobTitleDialog=$$v},expression:"showJobTitleDialog"}},[_c('v-form',{ref:"titleForm",on:{"submit":function($event){$event.preventDefault();return _vm.saveJobTitle.apply(null, arguments)}}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Create Job Title ")]),_c('v-card-text',[_c('v-text-field',{attrs:{"rules":_vm.nameRules,"label":"Job Title"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.showJobTitleDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","type":"submit","text":""}},[_vm._v(" Save ")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"490"},model:{value:(_vm.showJobTitleDeleteDialog),callback:function ($$v) {_vm.showJobTitleDeleteDialog=$$v},expression:"showJobTitleDeleteDialog"}},[(_vm.jobTitle)?_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Delete Job Title ")]),_c('v-card-text',[_vm._v(" Confirm to delete "+_vm._s(_vm.jobTitle.name)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.showJobTitleDeleteDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.removeJobTitle(_vm.jobTitle)}}},[_vm._v(" Delete ")])],1)],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticStyle:{"text-align":"right"}},[_c('v-btn',{attrs:{"elevation":"0","small":""},on:{"click":function($event){_vm.showJobTitleDialog = true;
          _vm.resetForm();}}},[_vm._v(" Add Job Title ")]),_vm._v("   "),_c('v-btn',{attrs:{"elevation":"0","small":""}},[_c('v-icon',[_vm._v("mdi-export-variant")]),_vm._v(" Export ")],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.jobTitlePage.docs,"options":_vm.options,"server-items-length":_vm.jobTitlePage.total,"no-data-text":"No jobTitles to display"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3 mr-4",attrs:{"elevation":"1","fab":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.jobTitle = item;
                _vm.showJobTitleDialog = true;}}},[_vm._v(" Edit ")])],1),_c('v-list-item',{on:{"click":function($event){_vm.jobTitle = item;
              _vm.showJobTitleDeleteDialog = true;}}},[_c('v-list-item-title',{staticStyle:{"cursor":"pointer"}},[_vm._v(" Delete ")])],1)],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }