


















































import Vue, { PropType } from "vue";
import { createNamespacedHelpers } from "vuex";

import permissionGroupStoreModule from "@/store/modules/permissionGroup";

const { mapActions: permissionGroupActions } = createNamespacedHelpers(
  "PERMISSION_GROUP_FORM"
);

import { PermissionGroup } from "@/types";

type ACL = {
  name: string;
  basic: boolean;
  low: boolean;
  medium: boolean;
  owner: boolean;
};

export default Vue.extend<any, any, any, any>({
  name: "PermissionForm",
  props: {
    permissionGroup: {
      type: Object as PropType<PermissionGroup>,
      required: false,
    },
  },
  computed: {
    permissions() {
      return this.permissionGroup.permissions.map((p) => ({
        headers: [
          {
            text: p.section,
            align: "start",
            sortable: true,
            value: "name",
          },
          { text: "Basic", value: "basic", sortable: false },
          { text: "Low", value: "low", sortable: false },
          { text: "Medium", value: "medium", sortable: false },
          { text: "Owner", value: "owner", sortable: false },
        ],
        acl: p.acl,
        basic: false,
        medium: false,
        low: false,
        owner: false,
      }));
    },
  },
  methods: {
    ...permissionGroupActions(["updatePermissionGroup"]),
    updatePermission() {
      this.updatePermissionGroup({
        id: this.permissionGroup._id,
        permissionGroup: {
          permissions: this.permissions.map((p) => ({
            section: p.headers[0].text.toLowerCase(),
            acl: p.acl,
          })),
        },
      }).then((pg) => {
        if (pg) this.$emit("group-updated", pg);
      });
    },
    cancel() {
      this.$emit("cancel", true);
    },
    toggleBasic(indx: number) {
      this.permissions[indx].basic = !this.permissions[indx].basic;
      this.permissions[indx].acl.map((a) => {
        a.basic = this.permissions[indx].basic;
        a.low = false;
        a.medium = false;
        a.owner = false;
      });
    },
    toggleMedium(indx: number) {
      this.permissions[indx].medium = !this.permissions[indx].medium;
      this.permissions[indx].acl.map((a) => {
        a.basic = false;
        a.low = false;
        a.medium = this.permissions[indx].medium;
        a.owner = false;
      });
    },
    toggleLow(indx: number) {
      this.permissions[indx].low = !this.permissions[indx].low;
      this.permissions[indx].acl.map((a) => {
        a.basic = false;
        a.low = this.permissions[indx].low;
        a.medium = false;
        a.owner = false;
      });
    },
    toggleOwner(indx: number) {
      this.permissions[indx].owner = !this.permissions[indx].owner;
      this.permissions[indx].acl.map((a) => {
        a.basic = false;
        a.low = false;
        a.medium = false;
        a.owner = this.permissions[indx].owner;
      });
    },
    updateAcl(acl: ACL, index: "basic" | "low" | "medium" | "owner") {
      if (index != "basic") acl.basic = false;
      if (index != "low") acl.low = false;
      if (index != "medium") acl.medium = false;
      if (index != "owner") acl.owner = false;
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("PERMISSION_GROUP_FORM")) {
      this.$store.registerModule(
        "PERMISSION_GROUP_FORM",
        permissionGroupStoreModule
      );
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("PERMISSION_GROUP_FORM");
  },
});
