






































































import Vue, { PropType } from "vue";

import { Permission } from "@/types";

export default Vue.extend<any, any, any, any>({
  name: "EditPermissionForm",
  props: {
    permission: {
      type: Object as PropType<Permission>,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
        class: "capitalize bold",
      },
      { text: "Basic", value: "basic", sortable: false },
      { text: "Low", value: "low", sortable: false },
      { text: "Medium", value: "medium", sortable: false },
      { text: "Owner", value: "owner", sortable: false },
    ],
    acl: [] as Permission["acl"],
    basic: false,
    medium: false,
    low: false,
    owner: false,
  }),
  created() {
    this.acl = this.permission.acl;
    this.headers[0].text = this.permission.section;
  },
  methods: {
    updatePermission(index?: number, aclKey?: string) {
      if (this.role.toLowerCase() === "owner") {
        this.$store.dispatch("setToast", {
          title: "Request failed!",
          type: "error",
          text: "Owmer Permission cannot be editted",
        });
        return;
      }
      if (index !== undefined && aclKey) {
        const val =
          this.acl[index as number][
            aclKey as "name" | "basic" | "low" | "medium" | "owner"
          ];

        if (val === true) {
          if (aclKey !== "basic") this.acl[index as number].basic = false;
          if (aclKey !== "low") this.acl[index as number].low = false;
          if (aclKey !== "medium") this.acl[index as number].medium = false;
          if (aclKey !== "owner") this.acl[index as number].owner = false;
        }
      }

      this.$emit("update-permission", {
        section: this.permission.section,
        permission: this.permission,
      });
    },
    toggleBasic() {
      this.basic = !this.basic;
      this.acl.map((a) => {
        a.basic = this.basic;
        a.medium = false;
        a.low = false;
        a.owner = false;
      });
      this.updatePermission();
    },
    toggleMedium() {
      this.medium = !this.medium;
      this.acl.map((a) => {
        a.medium = this.medium;
        a.basic = false;
        a.low = false;
        a.owner = false;
      });
      this.updatePermission();
    },
    toggleLow() {
      this.low = !this.low;
      this.acl.map((a) => {
        a.low = this.low;
        a.basic = false;
        a.medium = false;
        a.owner = false;
      });
      this.updatePermission();
    },
    toggleOwner() {
      this.owner = !this.owner;
      this.acl.map((a) => {
        a.owner = this.owner;
        a.basic = false;
        a.medium = false;
        a.low = false;
      });
      this.updatePermission();
    },
  },
});
