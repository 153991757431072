import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { Roster, Page } from "@/types";

type RosterState = {
  rosterPage: Page<Roster>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rosterAggregate: any[];
};

const roster: Module<RosterState, unknown> = {
  namespaced: true,
  state: () => ({
    rosterPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
    rosterAggregate: [],
  }),
  getters: {
    getRoster: (state) => (rosterId: string) =>
      state.rosterPage.docs.find((c) => c._id === rosterId),
    rosterPage: (state) => state.rosterPage,
    rosterAggregate: (state) => state.rosterAggregate,
  },
  mutations: {
    ADD_ROSTER: (state, _roster) => {
      let idx = -1;
      state.rosterPage.docs.map((r, i) => {
        if (r._id === _roster._id) idx = i;
      });
      if (idx === -1) state.rosterPage.docs.push(_roster);
      else Vue.set(state.rosterPage.docs, idx, _roster);
    },
    SET_ROSTER_PAGE: (state, list) => {
      state.rosterPage = list;
    },
    REMOVE_ROSTER(state, roster) {
      let idx = -1;
      state.rosterPage.docs.map((r, i) => {
        if (r._id === roster._id) idx = i;
      });
      if (idx > -1) state.rosterPage.docs.splice(idx, 1);
    },
    SET_ROSTER_AGGREGATE: (state, aggregate) => {
      state.rosterAggregate = aggregate;
    },
  },
  actions: {
    fetchRoster(context, params = "") {
      api
        .get(`/v1/roster${params}`)
        .then((response) => {
          context.commit("ADD_ROSTER", response.data.roster);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchRosterList(context, params = "") {
      return await api
        .get(`/v1/roster${params}`)
        .then((response) => {
          context.commit("SET_ROSTER_PAGE", response.data.rosterPage);
          return response.data.rosterPage;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createRoster(context, payload) {
      return await api
        .post(`/v1/roster`, payload)
        .then((response) => {
          context.commit("ADD_ROSTER", response.data.roster);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Roster created",
            },
            { root: true }
          );
          return response.data.roster;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async bulkCreateRoster(context, payload) {
      return await api
        .post(`/v1/roster/bulk`, payload)
        .then((response) => {
          response.data.roster.map((r: Roster) =>
            context.commit("ADD_ROSTER", r)
          );
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Roster saved",
            },
            { root: true }
          );
          return response.data.roster;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async bulkUpdateRoster(context, payload) {
      return await api
        .put(`/v1/roster/bulk`, payload)
        .then((response) => {
          response.data.roster.map((r: Roster) =>
            context.commit("ADD_ROSTER", r)
          );
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Roster saved",
            },
            { root: true }
          );
          return response.data.roster;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updateRoster(context, data: { id: string; roster: Roster }) {
      return await api
        .put(`/v1/roster/${data.id}`, data.roster)
        .then((response) => {
          context.commit("ADD_ROSTER", response.data.roster);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Roster updated",
            },
            { root: true }
          );
          return response.data.roster;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    deleteRoster(context, id) {
      api
        .delete(`/v1/roster/${id}`)
        .then((response) => {
          context.commit("REMOVE_ROSTER", response.data.roster);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Roster entry deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchRosterAggregate(context, params = "") {
      return await api
        .get(`/v1/roster-entry/aggregate${params}`)
        .then((response) => {
          context.commit("SET_ROSTER_AGGREGATE", response.data.rosterAggregate);
          return response.data.rosterAggregate;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default roster;
