var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('leave-form',{on:{"cancel":function($event){_vm.showDialog = false},"data-saved":_vm.closeModal}})],1),_c('v-dialog',{attrs:{"width":"550"},model:{value:(_vm.showWorkingHoursDialog),callback:function ($$v) {_vm.showWorkingHoursDialog=$$v},expression:"showWorkingHoursDialog"}},[_c('leave-working-hours-form',{attrs:{"leave":_vm.leave},on:{"data-saved":function($event){_vm.showWorkingHoursDialog = false;
        _vm.leave = {};},"cancel":function($event){_vm.showWorkingHoursDialog = false}}})],1),_c('v-row',[_c('v-col',{attrs:{"md":"8"}},[_vm._v("   ")]),_c('v-col',{staticClass:"text-right",attrs:{"md":"4"}},[_c('v-btn',{attrs:{"elevation":"0"}},[_c('v-icon',[_vm._v("mdi-export-variant")]),_vm._v(" Export ")],1),_c('v-btn',{attrs:{"elevation":"0"},on:{"click":function($event){_vm.showDialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add Leave date ")],1)],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"filters"},[_c('v-btn',{attrs:{"rounded":"","icon":""}},[_c('v-icon',[_vm._v("mdi-filter-variant")])],1),_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin":"0 2px"},attrs:{"outlined":""}},'v-btn',attrs,false),on),[_c('span',[_vm._v("Location: "+_vm._s(_vm.selectedLocation))]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',_vm._l((['All']),function(item){return _c('v-list-item',{key:item,on:{"click":function($event){_vm.selectedLocation = item}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1),_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin":"0 2px"},attrs:{"outlined":""}},'v-btn',attrs,false),on),[_c('span',[_vm._v("Staff: "+_vm._s(_vm.selectedStaff))]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',_vm._l((['All']),function(item){return _c('v-list-item',{key:item,on:{"click":function($event){_vm.selectedStaff = item}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1)],1)])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.leavePage.docs,"options":_vm.options,"server-items-length":_vm.leavePage.total,"no-data-text":"No leaves to display"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c('v-avatar',[(item.employee.avatar)?_c('img',{attrs:{"src":(_vm.apiUrl + "/v1/file/" + (item.employee.avatar.filename)),"alt":item.name}}):_c('img',{attrs:{"src":require("../../assets/avatar.png"),"alt":item.employee.fullName}})]),_vm._v("   "+_vm._s(item.employee.fullName)+" ")]}},{key:"item.duration",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readableDate")(item.startDate,"D MMM YYYY"))+" - "+_vm._s(_vm._f("readableDate")(item.endDate,"D MMM YYYY"))+" ")]}},{key:"item.days",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNumberofDays(item.startDate, item.endDate))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }