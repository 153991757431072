var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"roster"},[_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('slot-form',{attrs:{"roster":_vm.roster},on:{"data-saved":function($event){_vm.fetchData();
        _vm.showDialog = false;},"cancel":function($event){_vm.fetchData();
        _vm.showDialog = false;},"delete":function($event){_vm.showDialog = false},"add-date":_vm.closeModal}})],1),_c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.showRosterDialog),callback:function ($$v) {_vm.showRosterDialog=$$v},expression:"showRosterDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Edit Roster")]),_c('v-card-text',[_c('v-autocomplete',{attrs:{"items":_vm.employeePage.docs,"label":"Select Employee","item-text":"fullName","return-object":""},model:{value:(_vm.employee),callback:function ($$v) {_vm.employee=$$v},expression:"employee"}}),(_vm.employee)?_c('edit-roster-form',{attrs:{"employee":_vm.employee},on:{"data-saved":_vm.closeModal,"cancel":function($event){_vm.showRosterDialog = false;
            _vm.employee = undefined;}}}):_vm._e()],1)],1)],1),_c('v-row',{staticClass:"_row"},[_c('v-col',{staticClass:"_col",attrs:{"cols":"4"}},[_vm._v("Roster")]),_c('v-col',{staticClass:"_col",staticStyle:{"text-align":"center"},attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"icon":"","rounded":""},on:{"click":_vm.prev}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-less-than")])],1),_vm._v(" "+_vm._s(_vm._f("readableDate")(_vm.startDate,"MMM D"))+" - "+_vm._s(_vm._f("readableDate")(_vm.endDate,"MMM D"))+", "+_vm._s(_vm._f("readableDate")(_vm.endDate,"YYYY"))+" "),_c('v-btn',{attrs:{"icon":"","rounded":""},on:{"click":_vm.next}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-greater-than")])],1)],1),_c('v-col',{staticClass:"_col",staticStyle:{"text-align":"right"},attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.employee = undefined;
          _vm.showRosterDialog = true;}}},[_vm._v(" Edit ")])],1)],1),_c('v-row',[_c('v-col',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',{staticClass:"filters"},[_c('v-btn',{attrs:{"rounded":"","icon":""}},[_c('v-icon',[_vm._v("mdi-filter-variant")])],1),_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin":"0 2px"},attrs:{"outlined":""}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.selectedStaff))]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',_vm._l((['All Staff']),function(item){return _c('v-list-item',{key:item,on:{"click":function($event){_vm.selectedStaff = item}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1),_c('v-autocomplete',{staticClass:"search-field",attrs:{"items":[],"item-text":"fullName","item-value":"_id","height":"20","placeholder":"Search"}})],1)])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.rosterAggregate,"items-per-page":8,"options":_vm.options,"no-data-text":"No roster to display"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"flex-start","align-items":"center"}},[(item._id.avatar)?_c('v-avatar',[_c('img',{attrs:{"src":(_vm.apiUrl + "/v1/file/" + (item._id.avatar.filename)),"alt":""}})]):_c('v-avatar',[_c('img',{attrs:{"src":require("../../assets/avatar.png"),"alt":item.name}})]),_c('span',{staticStyle:{"white-space":"initial","margin-left":"5px"}},[_vm._v(" "+_vm._s(item._id.fullName)+" ")])],1)]}},{key:("item." + (_vm.dates[0])),fn:function(ref){
          var item = ref.item;
return _vm._l((item.slots),function(d,i){return _c('div',{key:i},[(_vm.compareDates(_vm.headers[1].date, d.date))?_c('div',[_c('v-chip',{staticClass:"slot-chip",attrs:{"color":"#F7F8FF"},on:{"click":function($event){return _vm.editSlot(d)}}},[_vm._v(" "+_vm._s(d.slot.startTime)+" - "+_vm._s(d.slot.endTime)+" ")])],1):_vm._e()])})}},{key:("item." + (_vm.dates[1])),fn:function(ref){
          var item = ref.item;
return _vm._l((item.slots),function(d,i){return _c('div',{key:i},[(_vm.compareDates(_vm.headers[2].date, d.date))?_c('div',[_c('v-chip',{staticClass:"slot-chip",attrs:{"color":"#F7F8FF"},on:{"click":function($event){return _vm.editSlot(d)}}},[_vm._v(" "+_vm._s(d.slot.startTime)+" - "+_vm._s(d.slot.endTime)+" ")])],1):_vm._e()])})}},{key:("item." + (_vm.dates[2])),fn:function(ref){
          var item = ref.item;
return _vm._l((item.slots),function(d,i){return _c('div',{key:i},[(_vm.compareDates(_vm.headers[3].date, d.date))?_c('div',[_c('v-chip',{staticClass:"slot-chip",attrs:{"color":"#F7F8FF"},on:{"click":function($event){return _vm.editSlot(d)}}},[_vm._v(" "+_vm._s(d.slot.startTime)+" - "+_vm._s(d.slot.endTime)+" ")])],1):_vm._e()])})}},{key:("item." + (_vm.dates[3])),fn:function(ref){
          var item = ref.item;
return _vm._l((item.slots),function(d,i){return _c('div',{key:i},[(_vm.compareDates(_vm.headers[4].date, d.date))?_c('div',[_c('v-chip',{staticClass:"slot-chip",attrs:{"color":"#F7F8FF"},on:{"click":function($event){return _vm.editSlot(d)}}},[_vm._v(" "+_vm._s(d.slot.startTime)+" - "+_vm._s(d.slot.endTime)+" ")])],1):_vm._e()])})}},{key:("item." + (_vm.dates[4])),fn:function(ref){
          var item = ref.item;
return _vm._l((item.slots),function(d,i){return _c('div',{key:i},[(_vm.compareDates(_vm.headers[5].date, d.date))?_c('div',[_c('v-chip',{staticClass:"slot-chip",attrs:{"color":"#F7F8FF"},on:{"click":function($event){return _vm.editSlot(d)}}},[_vm._v(" "+_vm._s(d.slot.startTime)+" - "+_vm._s(d.slot.endTime)+" ")])],1):_vm._e()])})}},{key:("item." + (_vm.dates[5])),fn:function(ref){
          var item = ref.item;
return _vm._l((item.slots),function(d,i){return _c('div',{key:i},[(_vm.compareDates(_vm.headers[6].date, d.date))?_c('div',[_c('v-chip',{staticClass:"slot-chip",attrs:{"color":"#F7F8FF"},on:{"click":function($event){return _vm.editSlot(d)}}},[_vm._v(" "+_vm._s(d.slot.startTime)+" - "+_vm._s(d.slot.endTime)+" ")])],1):_vm._e()])})}},{key:("item." + (_vm.dates[6])),fn:function(ref){
          var item = ref.item;
return _vm._l((item.slots),function(d,i){return _c('div',{key:i},[(_vm.compareDates(_vm.headers[7].date, d.date))?_c('div',[_c('v-chip',{staticClass:"slot-chip",attrs:{"color":"#F7F8FF"},on:{"click":function($event){return _vm.editSlot(d)}}},[_vm._v(" "+_vm._s(d.slot.startTime)+" - "+_vm._s(d.slot.endTime)+" ")])],1):_vm._e()])})}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }