










































































































































































































































import Vue from "vue";
import moment from "moment-timezone";
const tz = localStorage.getItem("tz") ?? "Africa/Nairobi";
import { createNamespacedHelpers } from "vuex";

import SlotForm from "./RosterForm.vue";
import { Business, Role, Roster } from "@/types";

import rosterStoreModule from "@/store/modules/roster";
import EditRosterForm from "./EditRosterForm.vue";
import employeeStoreModule from "@/store/modules/employee";

const { mapActions: rosterActions, mapGetters: rosterGetters } =
  createNamespacedHelpers("ROSTER_LIST");
const { mapActions: employeeActions, mapGetters: employeeGetters } =
  createNamespacedHelpers("EMPLOYEES");

const VUE_APP_API_URL = process.env.VUE_APP_API_URL;

export default Vue.extend<any, any, any, any>({
  components: { SlotForm, EditRosterForm },
  name: "Roster",
  data: () => ({
    roster: {} as Roster,
    selectedStaff: "All Staff",
    showDialog: false,
    startDate: moment().startOf("week").toISOString(),
    endDate: moment().endOf("week").toISOString(),
    headers: [] as { text: string; value: string; date: string }[],
    dates: [] as string[],
    options: {} as { page: number },
    apiUrl: VUE_APP_API_URL,
    showRosterDialog: false,
    employee: undefined,
  }),
  watch: {
    role() {
      this.fetchData();
    },
    // options: {
    //   handler() {
    //     this.fetchData();
    //   },
    //   deep: true,
    // },
  },
  created() {
    this.fetchData();
  },
  computed: {
    ...rosterGetters(["rosterAggregate"]),
    ...employeeGetters(["employeePage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...rosterActions(["fetchRosterAggregate", "updateRoster", "deleteRoster"]),
    ...employeeActions(["fetchEmployeeList"]),
    prev() {
      this.startDate = moment(this.startDate).subtract(1, "week").toISOString();
      this.endDate = moment(this.endDate).subtract(1, "week").toISOString();
      this.fetchData();
    },
    next() {
      this.startDate = moment(this.startDate).add(1, "week").toISOString();
      this.endDate = moment(this.endDate).add(1, "week").toISOString();
      this.fetchData();
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    editSlot(roster: Roster) {
      console.log("slot: ", roster);
      this.showDialog = true;
      this.roster = roster;
    },
    fetchData() {
      // const params = this.client ? `?clientId=${this.client?._id}` : "";
      const startDate = moment(this.startDate).startOf("week").toISOString();
      // .tz(tz).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
      const endDate = moment(this.endDate).endOf("week").toISOString();
      // .tz(tz).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");

      if (this.role) {
        const params = `?businessId=${
          (this.role.business as Business)._id
        }&startDate=${startDate}&endDate=${endDate}`;
        this.fetchRosterAggregate(params);
        this.fetchEmployeeList(
          `?businessId=${(this.role.business as Business)._id}`
        );
      }

      this.dates = [];
      this.headers = [
        {
          text: "Name",
          value: "name",
          date: "",
        },
      ];

      for (
        let d = new Date(startDate).getTime();
        d <= new Date(endDate).getTime();
        d += 1000 * 60 * 60 * 24
      ) {
        const dStr = `${new Date(d).getDate()} ${moment(new Date(d))
          .tz(tz)
          .format("ddd")}`;
        this.dates.push(dStr);
        this.headers.push({
          text: dStr,
          value: dStr,
          date: new Date(d).toISOString(),
        });
      }
    },
    compareDates(d1: string, d2: string) {
      return (
        moment(d1).tz(tz).format("YYYY-MM-DD") ===
        moment(d2).tz(tz).format("YYYY-MM-DD")
      );
    },
    closeModal() {
      this.fetchData();
      this.showDialog = false;
      this.showRosterDialog = false;
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("ROSTER_LIST")) {
      this.$store.registerModule("ROSTER_LIST", rosterStoreModule);
    }
    if (!this.$store.hasModule("EMPLOYEES")) {
      this.$store.registerModule("EMPLOYEES", employeeStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("ROSTER_LIST");
    this.$store.unregisterModule("EMPLOYEES");
  },
});
