









































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import moment from "moment-timezone";
const tz = localStorage.getItem("tz") ?? "Africa/Nairobi";

import leaveStoreModule from "@/store/modules/leave";
import { Business, Role } from "@/types";
import LeaveForm from "./LeaveForm.vue";

const { mapActions: leaveActions, mapGetters: leaveGetters } =
  createNamespacedHelpers("LEAVE_LIST");

const VUE_APP_API_URL = process.env.VUE_APP_API_URL;

export default Vue.extend<any, any, any, any>({
  components: { LeaveForm },
  name: "Leaves",
  data: () => ({
    leave: undefined,
    showDialog: false,
    showWorkingHoursDialog: false,
    selectedLocation: "All",
    selectedStaff: "All",
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Duration", value: "duration" },
      { text: "No. of days", value: "days" },
      // { text: "Location", value: "location" },
      { text: "Description", value: "description" },
    ],
    options: {} as { page: number },
    apiUrl: VUE_APP_API_URL,
  }),
  watch: {
    role() {
      if (this.role) {
        this.fetchLeaves();
      }
    },
    options: {
      handler() {
        this.fetchLeaves();
      },
      deep: true,
    },
  },
  computed: {
    ...leaveGetters(["leavePage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...leaveActions(["fetchLeaveList", "updateLeave", "deleteLeave"]),
    getNumberofDays(start: Date, end: Date) {
      return moment(end).diff(start, "days");
    },
    fetchLeaves() {
      if (this.role) {
        const params = `?businessId=${
          (this.role.business as Business)?._id
        }&page=${this.options.page || 1}`;
        this.fetchLeaveList(params);
      }
    },
    closeModal() {
      this.showDialog = false;
      this.fetchLeaves();
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("LEAVE_LIST")) {
      this.$store.registerModule("LEAVE_LIST", leaveStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("LEAVE_LIST");
  },
});
