


































































































































































import Vue, { VueConstructor } from "vue";
import { createNamespacedHelpers } from "vuex";

import employeeStoreModule from "@/store/modules/employee";
import pgStoreModule from "@/store/modules/permissionGroup";
import employeePermissionStoreModule from "@/store/modules/employeePermission";
import serviceStoreModule from "@/store/modules/service";
import jobTitleStoreModule from "@/store/modules/jobTitle";
import { Business, Employee, Role } from "@/types";
import { PHONE_REGEX } from "@/util/constants";

const { mapActions: employeeActions } =
  createNamespacedHelpers("EMPLOYEE_FORM");

const { mapActions: pgActions, mapGetters: pgGetters } =
  createNamespacedHelpers("EMPLOYEE_PERMISSION_GROUP");

const { mapActions: employeePermissionActions } = createNamespacedHelpers(
  "EMPLOYEE_PERMISSIONS"
);

const { mapGetters: serviceGettters, mapActions: serviceActions } =
  createNamespacedHelpers("SERVICES_EMPLOYEE_FORM");

const { mapGetters: jobTitleGetters, mapActions: jobTitleActions } =
  createNamespacedHelpers("JOB_TITLE_LIST");

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        imageField: HTMLInputElement;
      };
    }
  >
).extend<any, any, any, any>({
  name: "EmployeeForm",
  data() {
    return {
      valid: false,
      fullName: "",
      phone: "",
      email: "",
      dob: "",
      gender: "",
      permissionGroupId: "",
      jobTitleId: "",
      commissionRate: "",
      serviceIds: [],
      fullNameRules: [(v: string) => !!v || "Full Name is required"],
      phoneRules: [
        /*(v: string) => !!v || "Phone is required"*/
        (v: string) =>
          PHONE_REGEX.test(v) || "A valid Phone number is required",
      ],
      emailRules: [(v: string) => !!v || "Email is required"],
      dobRules: [(v: string) => !!v || "Date of Birth is required"],
      genderRules: [(v: string) => !!v || "Gender is required"],
      permissionGroupRules: [
        /*(v: string) => !!v || "Permission group is required",*/
      ],
      jobTitleRules: [(v: string) => !!v || "Title is required"],
      servicesRules: [
        /*(v: string) => !!v || "Services field is required"*/
      ],
      image: undefined as undefined | File,
      address: "",
    };
  },
  mounted() {
    this.resetForm();
  },
  created() {
    this.fetchData();
  },
  watch: {
    role: "fetchData",
  },
  computed: {
    ...pgGetters(["permissionGroupPage"]),
    ...serviceGettters(["servicePage"]),
    ...jobTitleGetters(["jobTitlePage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...employeeActions(["createEmployee"]),
    ...pgActions(["fetchPermissionGroupList"]),
    ...employeePermissionActions(["createEmployeePermission"]),
    ...serviceActions(["bulkUpdateServicePerformers", "fetchServiceList"]),
    ...jobTitleActions(["fetchJobTitleList"]),
    validateForm() {
      const valid = (
        this.$refs.employeeForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;
      const formData = new FormData();
      formData.append("businessId", (this.role.business as Business)._id);
      formData.append("fullName", this.fullName);
      formData.append("phone", this.phone);
      formData.append("email", this.email);
      formData.append("jobTitleId", this.jobTitleId);
      formData.append("commissionRate", this.commissionRate);
      formData.append("gender", this.gender);
      formData.append("dob", this.dob);
      formData.append("address", this.address);
      if (this.image) formData.append("file", this.image as File);
      this.createEmployee(formData).then((employee: Employee) => {
        if (employee) {
          const p1 = this.permissionGroupId
            ? this.createEmployeePermission({
                employeeId: employee._id,
                accessType: "read",
                permissionGroupId: this.permissionGroupId,
              })
            : null;

          let p2;
          if (this.serviceIds.length) {
            const performers = this.serviceIds.map((id) => ({
              employeeId: employee._id,
              serviceId: id,
            }));
            p2 = this.bulkUpdateServicePerformers({ performers });
          }

          Promise.all([p1, p2]).then((ep) => {
            if (ep) this.$emit("data-saved", employee);
          });
        }
      });
    },
    resetForm() {
      this.fullName = "";
      this.phone = "";
      this.dob = "";
      this.email = "";
      this.gender = "";
      this.permissionGroupId = "";
      this.commissionRate = "";
      this.jobTitleId = "";
      this.image = undefined;
      this.address = "";
      (
        this.$refs.employeeForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();

      const bid = (this.role?.business as Business)._id;
      if (bid) this.fetchPermissionGroupList(`?businessId=${bid}`);
    },
    cancel() {
      this.$emit("cancel", true);
    },
    upload(image: File) {
      this.image = image;
    },
    dropHandler(ev: DragEvent) {
      const file = ev.dataTransfer?.files[0] as File;

      this.image = file;
    },
    searchJobTitle(q: string) {
      if (q) {
        const bid = (this.role.business as Business)._id;
        this.fetchJobTitleList(`?businessId=${bid}&q=${q}`);
      }
    },
    fetchData() {
      if (this.role) {
        const bid = (this.role.business as Business)._id;
        this.fetchPermissionGroupList(`?businessId=${bid}`);
        this.fetchServiceList(`?businessId=${bid}`);
        this.fetchJobTitleList(`?businessId=${bid}`);
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("EMPLOYEE_FORM")) {
      this.$store.registerModule("EMPLOYEE_FORM", employeeStoreModule);
    }
    if (!this.$store.hasModule("EMPLOYEE_PERMISSION_GROUP")) {
      this.$store.registerModule("EMPLOYEE_PERMISSION_GROUP", pgStoreModule);
    }
    if (!this.$store.hasModule("EMPLOYEE_PERMISSIONS")) {
      this.$store.registerModule(
        "EMPLOYEE_PERMISSIONS",
        employeePermissionStoreModule
      );
    }
    if (!this.$store.hasModule("SERVICES_EMPLOYEE_FORM")) {
      this.$store.registerModule("SERVICES_EMPLOYEE_FORM", serviceStoreModule);
    }
    if (!this.$store.hasModule("JOB_TITLE_LIST")) {
      this.$store.registerModule("JOB_TITLE_LIST", jobTitleStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("EMPLOYEE_FORM");
    this.$store.unregisterModule("EMPLOYEE_PERMISSION_GROUP");
    this.$store.unregisterModule("EMPLOYEE_PERMISSIONS");
    this.$store.unregisterModule("SERVICES_EMPLOYEE_FORM");
    this.$store.unregisterModule("JOB_TITLE_LIST");
  },
});
