













































































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import EditPermissionGroupForm from "./EditPermissionGroupForm.vue";
import PermissionGroupForm from "./PermissionGroupForm.vue";

import permissionGroupStoreModule from "@/store/modules/permissionGroup";
import EmployeePermissionList from "./EmployeePermissionList.vue";
import { Business, PermissionGroup, Role } from "@/types";
import PermissionsForm from "./PermissionsForm.vue";

const {
  mapActions: permissionGroupActions,
  mapGetters: permissionGroupGetters,
} = createNamespacedHelpers("PERMISSION_GROUPS");

export default Vue.extend<any, any, any, any>({
  components: {
    PermissionGroupForm,
    EditPermissionGroupForm,
    EmployeePermissionList,
    PermissionsForm,
  },
  name: "PermissionGroups",
  data: () => ({
    tab: -1,
    group: undefined,
    showGroupDialog: false,
    showAddGroupDialog: false,
    showDeleteGroupDialog: false,
    selectedGroup: undefined as undefined | PermissionGroup,
    total: 0,
    editPermissions: false,
  }),
  watch: {
    tab() {
      if (this.tab >= 0) {
        this.selectedGroup = this.permissionGroupPage.docs[this.tab];
      }
    },
    // role() {
    //   if (this.role) {
    //     console.log("set");
    //     this.fetchPermissionGroupList(
    //       `?businessId=${(this.role.business as Business)._id}`
    //     ).then(() => {
    //       this.tab = 0;
    //       this.selectedGroup = this.permissionGroupPage.docc[this.tab];
    //     });
    //   }
    // },
  },
  created() {
    if (this.role) {
      this.fetchPermissionGroupList(
        `?businessId=${(this.role.business as Business)._id}`
      ).then(() => {
        this.tab = 0;
        this.selectedGroup = this.permissionGroupPage.docs[this.tab];
      });
    }
  },
  computed: {
    ...permissionGroupGetters(["permissionGroupPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...permissionGroupActions([
      "fetchPermissionGroupList",
      "createPermissionGroup",
      "updatePermissionGroup",
      "deletePermissionGroup",
    ]),
    resetForm() {
      (
        this.$refs.permissionForm as Element & {
          resetForm: () => void;
        }
      )?.resetForm();
    },
    resetEditForm() {
      (
        this.$refs.editPermissionForm as Element & {
          resetForm: () => void;
        }
      )?.resetForm();
    },
    setTotal(n: number) {
      this.total = n;
    },
    closePermissionGroupModal(group: PermissionGroup) {
      this.showAddGroupDialog = false;
      this.fetchPermissionGroupList(
        `?businessId=${(this.role.business as Business)._id}`
      ).then(() => {
        this.tab = 0;
        this.selectedGroup = group;
      });
      this.editPermissions = true;
    },
    deleteGroup() {
      this.deletePermissionGroup(`/${this.selectedGroup?._id}`).then(
        (group) => {
          if (group) {
            this.fetchPermissionGroupList(
              `?businessId=${(this.role.business as Business)._id}`
            ).then(() => {
              this.tab = 0;
              this.selectedGroup = this.permissionGroupPage.docs[this.tab];
            });
          }
        }
      );
      this.showDeleteGroupDialog = false;
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("PERMISSION_GROUPS")) {
      this.$store.registerModule(
        "PERMISSION_GROUPS",
        permissionGroupStoreModule
      );
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("PERMISSION_GROUPS");
  },
});
