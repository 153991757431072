var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"employee-permission-list"},[_c('v-dialog',{attrs:{"width":"440"},model:{value:(_vm.showDeleteDialog),callback:function ($$v) {_vm.showDeleteDialog=$$v},expression:"showDeleteDialog"}},[(_vm.permission)?_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Confirm to delete permission ")]),_c('v-card-text',[_vm._v(" This action will delete "+_vm._s(((_vm.permission.employee.fullName) + "'s"))+" permission ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.showDeleteDialog = false}}},[_vm._v(" Disagree ")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.deleteEmployeePermission(("/" + (_vm.permission._id)));
            _vm.showDeleteDialog = false;}}},[_vm._v(" Agree ")])],1)],1):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.employeePermissionPage.docs,"options":_vm.options,"server-items-length":_vm.employeePermissionPage.total,"no-data-text":"No permissions to display"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
            var item = ref.item;
return [_c('v-avatar',[_c('img',{attrs:{"src":require("../../assets/avatar.png")}})]),_vm._v("   "),(item.employee)?_c('span',[_vm._v(_vm._s(item.employee.fullName))]):_vm._e()]}},{key:"item.email",fn:function(ref){
            var item = ref.item;
return [(item.employee)?_c('span',[_vm._v(" "+_vm._s(item.employee.email)+" ")]):_vm._e()]}},{key:"item.role",fn:function(ref){return [_vm._v(" "+_vm._s(_vm.permissionGroup.role)+" ")]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"rounded":"","icon":"","x-large":"","color":"#F21501"},on:{"click":function($event){_vm.permission = item;
          _vm.showDeleteDialog = true;}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }